import { VERSION } from './version';
import { components } from './components';
import { createPlugin } from '@bluebase/core';
import { filters } from './filters';
import { forms } from './forms';
import { lang } from './lang';
import { routes } from './routes';
import { screens } from './screens';
import { settings } from './settings';

export * from './exports';

export default createPlugin({
	description: 'Mevris Things App',
	key: 'things',
	name: 'Things',
	version: VERSION,

	icon: {
		component: 'ThingsAppIcon',
		type: 'component',
	},

	assets: {},

	components: {
		...components,
		...forms,
		...settings,
		...screens,
	},

	indexRoute: 'ThingsApp',

	filters: {
		...lang,
		...filters,
	},

	routes,
});
