import { ActivityIndicator, Dialog, Divider, List, StatefulComponent } from '@bluebase/components';
import React, { useState } from 'react';
import {
	ThingPlaceQuery,
	ThingPlaceUpdateMutation,
	ThingPlaceUpdateMutationUpdateFn,
} from '../../graphql';
import { useBlueBase, useIntl } from '@bluebase/core';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { LoadingState } from './LoadingState';
import { PlaceList } from '../../imports';
import get from 'lodash.get';

export interface PlaceSettingProps {
	thingId: string;
}

export const PlaceSetting = ({ thingId }: PlaceSettingProps) => {
	const BB = useBlueBase();
	const { __ } = useIntl();
	const [dialogVisible, setDialogVisible] = useState(false);

	const [mutate, { loading: mutating }] = useMutation(ThingPlaceUpdateMutation);
	const { data, loading, error } = useQuery(ThingPlaceQuery, { variables: { id: thingId } });

	const toggleDialog = () => setDialogVisible(!dialogVisible);

	async function updatePlace(node: any) {
		toggleDialog();
		try {
			await mutate({
				update: ThingPlaceUpdateMutationUpdateFn(thingId, BB),
				variables: {
					clientMutationId: 'clientMutationId',
					data: { id: thingId, place: node.id },
				},
			});
		} catch (e) {
			return BB.Logger.error('Error while mutating PlaceSetting', e);
		}
	}

	const placeId = get(data, 'node.place.id');
	const placeName = get(data, 'node.place.name');
	const placeAvatar = get(data, 'node.place.avatar');

	const avatar = placeId && placeAvatar ? { uri: placeAvatar } : 'PlaceTypePlaceholder';

	return (
		<StatefulComponent
			data={get(data, 'node')}
			loading={loading}
			error={error}
			loadingComponent={LoadingState}
		>
			<List.Item
				left={<List.Avatar type="image" variant="rounded" image={avatar} />}
				right={mutating && <ActivityIndicator />}
				title={__('Place')}
				description={placeName || __('Select a place where this thing belongs')}
				onPress={toggleDialog}
			/>
			<Dialog visible={dialogVisible} dismissable onDismiss={toggleDialog}>
				<List.Subheader>{__('Select a Place')}</List.Subheader>
				<Divider />
				<PlaceList itemsPerPage={10} onPress={updatePlace} />
			</Dialog>
		</StatefulComponent>
	);
};

PlaceSetting.displayName = 'PlaceSetting';
