import   * as React  from 'react';
import { StatefulComponent,View } from '@bluebase/components';
import { ThingDeleteMutation, ThingNodeQuery } from '../../graphql';
import { makeId,useBlueBase,useNavigation } from '@bluebase/core';
import { DangerSettingAction } from '../../imports';
import  { ThingListQuery }  from '@mevris/client-plugin-ui';
import get from 'lodash.get';
import { useQuery } from '@apollo/react-hooks';
import { useResponsiveListSizes } from '@blueeast/client-plugin-ui';
export interface DeleteSettingProps {
	thingId: string;
}

export const DeleteSetting = ({ thingId }: DeleteSettingProps) => {
	const { navigate } = useNavigation();
	const { data, error, loading } = useQuery(ThingNodeQuery, { variables: { id: thingId } });
	const { sizes, onLayout } = useResponsiveListSizes();
	const { Logger }=useBlueBase();
	const onSuccess = () => {
		navigate('ThingsApp');
	};
	const update=(store:any,updatedData:any) => {
		try{
		// Read the data from our cache for this query.
		const datas = store.readQuery({ query: ThingListQuery,
			variables:{
				avatarSize: Math.ceil(sizes.itemWidth),
				filter: { first: 10 }
		  } });

		  // Add our comment from the mutation to the end.
		const filteredArray=datas.viewer.me.things.edges.filter((item:any) => item.node.id!==updatedData.data.deleteThing.id);
		datas.viewer.me.things.edges=filteredArray;

		store.writeQuery({
			data:datas,
			query: ThingListQuery,
			variables:{
				avatarSize: Math.ceil(sizes.itemWidth),
				filter: { first: 10 }			  },
			 });
	}
		catch(e){
			return Logger.error(e);
		}
      };

	return (
		<StatefulComponent data={get(data, 'node.name')} loading={loading} error={error}>
		<View onLayout={onLayout}>
			<DangerSettingAction
				code={get(data, 'node.name')}
				onSuccess={onSuccess}
				mutation={{ mutation: ThingDeleteMutation,
					update,
					variables: {
						data: {
							clientMutationId: makeId(),
							id:thingId,
						},
					},


				}}
				schema={{ initialValues: { id: thingId, code: '' } }}
				iconProps={{ name: 'delete' }}
				title={'Delete this thing'}
				description={'Once you delete this thing, there is no going back, Please be certain'}
				formTitle={'Delete Thing'}
				formButtonTitle={'Delete this thing'}
				formDescription="Confirm your thing to delete"
				formTextFieldLabel="Thing Name"
			/>
			</View>
		</StatefulComponent>
	);
};

DeleteSetting.displayName = 'DeleteSetting';
