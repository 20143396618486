import BlueBasePluginApollo from '@bluebase/plugin-apollo';
import BlueBasePluginJsonSchemaComponents from '@bluebase/plugin-json-schema-components';
import BlueBasePluginLauncher from '@bluebase/plugin-launcher';
import BlueBasePluginMaterialUI from '@bluebase/plugin-material-ui';
import BlueBasePluginReactRouter from '@bluebase/plugin-react-router';
import BlueBasePluginResponsiveGrid from '@bluebase/plugin-responsive-grid';
import BlueBasePluginRnPlaceholder from '@bluebase/plugin-rn-placeholder';
import BlueBasePluginSettingsApp from '@bluebase/plugin-settings-app';
import BlueeastClientPluginLocationUi from '@blueeast/client-plugin-location-ui';
import BlueeastClientPluginUi from '@blueeast/client-plugin-ui';
import BlueeastPluginPermissionUi from '@blueeast/client-plugin-permissions-ui';
import { MaterialCommunityIcons } from '@bluebase/plugin-vector-icons';
import MevrisClientPluginUi from '@mevris/client-plugin-ui';
import MevrisPluginActivityStreams from '@mevris/client-plugin-activity-streams';
import MevrisPluginDeviceControllers from '@mevris/client-plugin-device-controllers';
import MevrisPluginReports from '@mevris/client-plugin-reports';
import MevrisPluginUserManagement from '@mevris/client-plugin-user-management';
import Plugin from '../../src';
import TaskBar from '@mevris/client-plugin-taskbar';

export const plugins = [
	BlueBasePluginMaterialUI,
	BlueBasePluginReactRouter,
	BlueBasePluginRnPlaceholder,
	BlueeastClientPluginLocationUi,
	BlueeastPluginPermissionUi,
	BlueeastClientPluginUi,
	MevrisClientPluginUi,
	BlueBasePluginApollo,
	BlueBasePluginJsonSchemaComponents,
	BlueBasePluginLauncher,
	BlueBasePluginResponsiveGrid,
	MaterialCommunityIcons,
	MevrisPluginReports,
	MevrisPluginActivityStreams,
	MevrisPluginDeviceControllers,
	MevrisPluginUserManagement,
	TaskBar,
	Plugin,
	BlueBasePluginSettingsApp,
];
