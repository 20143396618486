import { BlueBase, isMobile } from '@bluebase/core';
import { HeaderBackButton, Icon, RouteConfig } from '@bluebase/components';
import { NavigationOptionsFnParam } from '../types';
import { Platform } from 'react-native';
import React from 'react';
import { createSettingsRoutes } from '@bluebase/plugin-settings-app';
import { desktopRoutes } from './desktop';
import { mobileRoutes } from './mobile';

export const routes = async (BB: BlueBase): Promise<RouteConfig[]> => {
	const profilePageRoutes = isMobile() ? await mobileRoutes(BB) : await desktopRoutes(BB);

	const defaultRoutes = [
		{
			exact: true,
			name: 'ThingsApp',
			path: '',
			screen: 'ThingsAppScreen',
		},

		// settings page
		...createSettingsRoutes({
			filter: 'mevris.app.things.settings',

			mainRoute: {
				name: 'ThingSettings',
				path: ':thingId/settings',

				// We do this to check for 404, etc
				screen: 'ThingSettingsScreen',

				navigationOptions: ({ navigation, screenProps }: NavigationOptionsFnParam) => {
					const { intl } = screenProps;
					const { getParam } = navigation;

					const thingId = getParam('thingId', null);
					const onBackPress = () => navigation.navigate('ThingProfile', { thingId });

					const headerLeft =
						Platform.OS === 'web' ? <HeaderBackButton onPress={onBackPress} /> : undefined;

					return {
						headerLeft,
						title: intl.__('Thing Settings'),
					};
				},
			},

			pages: [
				{
					name: 'ThingSettingsGeneral',
					path: 'general',

					navigationOptions: {
						drawerIcon: Platform.OS === 'web' ? { type: 'icon', name: 'settings' } : <Icon name="settings" />,
						drawerLabel: 'General',
						title: 'General Settings',
					} as any,

					items: [
						{
							component: 'ThingGeneralSettings',
							description: 'Give your device a name and assign a site.',
							name: 'ThingGeneralSettings',
							title: 'General Information',
						},
						{
							component: 'ThingDisplayPictureSettings',
							description: 'Give your device a name and assign a site.',
							name: 'ThingDisplayPictureSettings',
							title: 'Display Picture',
						},
						{
							component: 'ThingDangerSettings',
							description: 'All data related to your asset will be lost.',
							name: 'ThingDangerSettings',
							title: 'Danger Zone',
						},
					],
				},
				{
					name: 'ThingSettingsWifi',
					path: 'wifi',

					navigationOptions: {
						drawerIcon: Platform.OS === 'web' ? { type: 'icon', name: 'wifi' } : <Icon name="wifi" />,
						drawerLabel: 'Wi-Fi',
						title: 'Wi-Fi Settings',
					} as any,

					items: [
						{
							component: 'ThingWifiSettings',
							description: 'View your asset network configurations.',
							name: 'ThingWifiSettings',
							title: 'Configurations',
						},
					],
				},
				{
					name: 'ThingSettingsInformation',
					path: 'information',

					navigationOptions: {
						drawerIcon: Platform.OS === 'web' ? { type: 'icon', name: 'information' } : <Icon name="information" />,
						drawerLabel: 'About',
						title: 'About',
					} as any,

					items: [
						{
							component: 'ThingProductDetailSettings',
							description: 'You can check all the detail of your thing here',
							name: 'ThingProductDetailSettings',
							title: 'Thing Info',
						},
						{
							component: 'ThingProductSettings',
							description: 'See the information about your product.',
							name: 'ThingProductSettings',
							title: 'Product',
						},
					],
				},
			],
		}),

		// overview page
		...profilePageRoutes,
	];

	const finalRoutes = await BB.Filters.run('mevris.app.things.routes', defaultRoutes);

	return finalRoutes;
};
