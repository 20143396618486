import { Icon, RouteConfig } from '@bluebase/components';

import { BlueBase } from '@bluebase/core';
import { NavigationOptionsFnParam } from '../types';
import React from 'react';
import { getTheme } from './getTheme';

export const mobileRoutes = async (BB: BlueBase): Promise<RouteConfig[]> => {
	const theme = getTheme(BB);

	return [
		{
			// exact: true,
			name: 'ThingProfile',
			path: ':thingId',
			screen: 'ThingProfileScreen',

			navigator: {
				type: 'tab',

				tabBarOptions: {
					labelStyle: {
						color: '#FFF',
						fontSize: 12,
					},
					scrollEnabled: true,
					showIcon: true,
					tabStyle: {
						color: '#FFF',
						width: 100,
					},

					style: {
						backgroundColor: theme.palette.primary.main,
						zIndex: 2000,
						...theme.elevation(4),
					},

					indicatorStyle: {
						backgroundColor: theme.palette.secondary.main,
					},
				},

				routes: await BB.Filters.run('mevris.app.things.routes.thing-profile.mobile', [
					{
						name: 'ThingProfileOverview',
						path: 'overview',
						screen: 'ThingProfileOverviewScreen',

						navigationOptions: ({ screenProps }: NavigationOptionsFnParam) => ({
							tabBarIcon: () => <Icon name="google-controller" color="#FFF" />,
							title: screenProps.intl.__('Controls'),
						}),
					},
					{
						name: 'ThingProfileSensors',
						path: 'sensors',
						screen: 'ThingProfileSensorsScreen',

						navigationOptions: ({ screenProps }: NavigationOptionsFnParam) => ({
							tabBarIcon: () => <Icon name="gauge" color="#FFF" />,
							title: screenProps.intl.__('Sensors'),
						}),
					},
				]),
			},
		},
	];
};
