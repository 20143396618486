import { BlueBase } from '@bluebase/core';
import { NavigationOptionsFnParam } from '../types';
import { RouteConfig } from '@bluebase/components';
import { getTheme } from './getTheme';

export const desktopRoutes = async (BB: BlueBase): Promise<RouteConfig[]> => {
	const theme = getTheme(BB);

	return [
		{
			// exact: true,
			name: 'ThingProfile',
			path: ':thingId',
			screen: 'ThingProfileScreen',

			navigator: {
				type: 'tab',

				routes: await BB.Filters.run('mevris.app.things.routes.thing-profile.desktop', [
					{
						name: 'ThingProfileOverview',
						path: 'overview',
						screen: 'ThingProfileOverviewScreen',

						navigationOptions: ({ screenProps }: NavigationOptionsFnParam) => ({
							title: screenProps.intl.__('Overview'),
						}),
					},
				]),

				tabBarOptions: {
					showIcon: true,
					style: {
						// TODO: Doesn't respond to theme changes...
						// backgroundColor: theme.palette.background.card,
						borderBottomColor: theme.palette.divider,
						borderBottomStyle: 'solid',
						borderBottomWidth: 1,
					},
				},
			},
		},
	];
};
