import { List } from '@bluebase/components';
import ProductInfoSetting from '../ProductInfoSettings';
import React from 'react';
import { useNavigation } from '@bluebase/core';

export const ThingProductSettings = () => {
	const { getParam } = useNavigation();

	const thingId = getParam('thingId', null);
	return (
		<List>
			<ProductInfoSetting thingId={thingId} />
		</List>
	);
};

ThingProductSettings.displayName = 'ThingProductSettings';
