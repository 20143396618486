import MevrisLocalDeviceController from '@mevris/device-controller-mevris-local';
import MevrisRemoteDeviceController from '@mevris/device-controller-mevris-remote';
import { plugins } from './plugins';

export default {
	configs: {
		// App Developer
		author: 'BlueEast',

		// Website URL of App Developer
		authorUrl: 'https://www.blueeast.com',

		// // Default status bar style on native
		// statusBarStyle: 'dark-content',

		// App Title
		title: 'Mevris',

		// App Version
		// version: VERSION,

		// 'theme.overrides': {
		// 	palette: {
		// 		background: { default: '#f4f5f7' },
		// 		primary: { main: 'black' },
		// 	},
		// },

		// Google Map Key
		'plugin.mapview.api-key': 'AIzaSyB3hi0m4WFm6oSaKNgk_qcg-vLwG7nwU7s' || null,

		// facebookAppId
		'plugin.user.management.facebook.appId': '549524105530640',

		// googleIos clientId
		'plugin.user.management.google.iosClientId':
			'585191277176-cc9jce9pr6q9to1i6dutvsbmks1rv634.apps.googleusercontent.com',
		// googleAndroid clientId
		'plugin.user.management.google.androidClientId':
			'10745795480-1mhkqkhmiulgparh9unprefet5m3vl64.apps.googleusercontent.com',

		// googleWeb clientId
		'plugin.user.management.google.webClientId':
			'10745795480-g8l7ft9mhdp7fihp3iv16ut68gd2s55k.apps.googleusercontent.com',
		// Screens to restrict behind Authentication
		'mevris.plugin.user-management.blacklist': ['HomeScreen'],

		// 'user.accessToken':
		// 	// tslint:disable-next-line: max-line-length
		// 	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiY3JlYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwidXBkYXRlZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMS4yNTNaIiwiaWQiOiI1Y2RiYzBmZDA4NWIxYzIxOGVmZTQ5MWQiLCJmaXJzdE5hbWUiOiJBbWFhciIsImxhc3ROYW1lIjoiSGFzc2FuIiwiZW1haWwiOiJhbWFhcmhhc3NhbmNzQGdtYWlsLmNvbSIsImVtYWlsVmVyaWZpZWQiOnRydWUsImlzU29jaWFsIjpmYWxzZSwic29jaWFsUHJvdmlkZXIiOiIiLCJwaG9uZSI6IjAzMzY1Njg5ODc4IiwiYWNjZXNzVG9rZW4iOiIiLCJhdmF0YXIiOiJodHRwczovL3MzLmFtYXpvbmF3cy5jb20vbWV2cmlzLWltYWdlLWNkbi9kZWZhdWx0LXByb2ZpbGUtYXZhdGFyLnBuZyIsIm1ldGFkYXRhIjpudWxsLCJzdGF0ZSI6bnVsbCwic3RhdGVNZXRhIjpudWxsLCJoZWFydGJlYXQiOiIiLCJ2ZXJpZmllZEF0IjoiMjAxOS0wNS0xNVQwNzozNDoyMC40NjlaIiwiaXNEZWxldGVkIjpmYWxzZSwiZGVsZXRlZEF0IjoiIiwibGVnYWN5QWNjb3VudElkIjoiIiwic2NoZW1hIjpudWxsLCJkZWxldGVkQnkiOm51bGwsInJvbGUiOiI1Y2FjOTBiNTg1N2U3ODU5MjNkMzJhYjAifSwiaWF0IjoxNTU3OTA1NzI3LCJleHAiOjE1NTc5MDcxNjcsImF1ZCI6Im1ldnJpcy5pbyIsImlzcyI6Im1ldnJpcy5pbyJ9.3LzorQezKKN-Lm86QKGqh23qLOrTVy1SEY3OUK0Pjdo',
		// 'user.id': 'UGVyc29uOjVjZGJjMGZkMDg1YjFjMjE4ZWZlNDkxZA==',

		// // Apollo Graphql Configs
		// 'plugin.apollo.httpLinkOptions': {
		// 	uri: 'https://api-qa.mevris.io/graphql',
		// },

		// 'user.accessToken':
		// 	// tslint:disable-next-line: max-line-length
		// 	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXJzb24iOnsiaWQiOiI1ZDI2ZDhmMzE4M2NlMDJmZTRmMWI0NjUifSwiaWF0IjoxNTc4OTMyNjQ4LCJleHAiOjE1Nzg5MzQwODgsImF1ZCI6Im1ldnJpcy5pbyIsImlzcyI6Im1ldnJpcy5pbyJ9.Ynh2karQakPadOEjbswUtZzBaK9s6LvnOpyhSVSHo4g',
		// 'user.id': 'UGVyc29uOjVkMjZkOGYzMTgzY2UwMmZlNGYxYjQ2NQ==',

		// Apollo Graphql Configs
		'plugin.apollo.httpLinkOptions': {
			uri: 'https://api3.mevris.io/graphql',
		},
	},

	deviceControllers: {
		MevrisLocalDeviceController,
		MevrisRemoteDeviceController,
	},

	plugins,
};
