import DeleteSetting from '../DeleteSetting';
import { List } from '@bluebase/components';
import React from 'react';
import { useNavigation } from '@bluebase/core';

export interface ThingDangerSettingsProps {}

export const ThingDangerSettings = (_props: ThingDangerSettingsProps) => {
	const { getParam } = useNavigation();

	const thingId = getParam('thingId', null);
	return (
		<List>
			<DeleteSetting thingId={thingId} />
		</List>
	);
};
