import { withThingValidation } from '../helpers';

export const screens = {
	ThingProfileOverviewScreen: {
		applyStyles: false,
		hocs: [withThingValidation],
		value: import('./ThingProfileOverviewScreen'),
	},
	ThingProfileScreen: import('./ThingProfileScreen'),
	ThingProfileSensorsScreen: {
		applyStyles: false,
		hocs: [withThingValidation],
		value: import('./ThingProfileSensorsScreen'),
	},
	ThingSettingsScreen: {
		applyStyles: false,
		hocs: [withThingValidation],
		value: import('./ThingSettingsScreen'),
	},
	ThingsAppScreen: import('./ThingsAppScreen'),
};
