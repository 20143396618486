import AvatarSetting from '../AvatarSetting';
import { List } from '@bluebase/components';
import React from 'react';
import { useNavigation } from '@bluebase/core';

export const ThingDisplayPictureSettings = () => {
	const { getParam } = useNavigation();

	const thingId = getParam('thingId', null);
	return (
		<List>
			<AvatarSetting thingId={thingId} />
		</List>
	);
};

ThingDisplayPictureSettings.displayName = 'ThingDisplayPictureSettings';
