import { Divider, List } from '@bluebase/components';

import NameSetting from '../NameSetting';
import PlaceSetting from '../PlaceSetting';
import React from 'react';
import { useNavigation } from '@bluebase/core';

export const ThingGeneralSettings = () => {
	const { getParam } = useNavigation();

	const thingId = getParam('thingId', null);
	return (
		<List>
			<NameSetting thingId={thingId} />
			<Divider inset />
			<PlaceSetting thingId={thingId} />
		</List>
	);
};

ThingGeneralSettings.displayName = 'ThingGeneralSettings';
