import { BlueBase, buildTheme } from '@bluebase/core';

// Dirty Hack
// Unfortunately, the way react-navigation is made, and the BlueBase Core
// architecture is made around it... we cannot have theme yet, because
// this code is executed before ThemeContext is created. So we have to
// create a fake theme. Hopefully, this will be fixed after react-navigation 5
// is released.
export function getTheme(BB: BlueBase) {
	const mode = BB.Configs.getValue('theme.mode');
	const overrides = BB.Configs.getValue('theme.overrides') || {};
	return buildTheme(mode)({ value: overrides });
}
